.c-dark-1 {
  color: var(--dark-1);
}

.c-gray-1 {
  color: var(--gray-1);
}

.c-dusk {
  color: var(--dusk);
}

.c-white {
  color: var(--white);
}

.c-black {
  color: var(--black);
}

.c-orange {
  color: var(--orange);
}
