// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** START STYLEGUIDE COLORS **/
  --green: #19ca6a;
  --orange: #ffaf37;
  --dusk: #838dae;
  --white: #fff;
  --light-cyan: #d1e7ed;
  --light-dusk: rgba(131, 141, 174, 0.15);
  --light-gray: #fcfcfc;
  --black: #000;
  --light-blue: #51abff;
  --whatsapp-green: #25d366;
  --light-gray-2: #e6e7ed;
  --facebook-blue: #4267b2;
  --instagram-purple: #c13584;
  --gray-1: #6b7580;
  --gray-3: #b0b8bf;
  --red: #f44;
  --dark-1: #1f2933;
  --status-i-background: #b3e5fc;
  --status-i-color: #23547b;
  --status-d-color: #8a5340;
  --status-d-background: #feedaf;
  --status-v-background: #eccfff;
  --status-v-color: #694382;
  --status-h-background: #ffd8b2;
  --status-h-color: #805b36;
  --status-c-color: #256029;
  --text: rgba(0, 0, 0, 0.87);
  --status-c-background: #c8e6c9;
  /** END STYLEGUIDE COLORS **/

  --ion-font-family: 'Poppins';
  --ion-background-color: var(--light-gray);
  --ion-text-color: var(--gray-1);
  --ion-tab-bar-background: var(--white);
  --ion-tab-bar-color: var(--dusk);
  --ion-tab-bar-color-selected: var(--green);
  --ion-tab-bar-border-color: transparent;
  // --ion-toolbar-background: transparent;
  --ion-toolbar-color: var(--dark-1);

  /** primary **/
  --ion-color-primary: #19ca6a;
  --ion-color-primary-rgb: 25, 202, 106;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #16b25d;
  --ion-color-primary-tint: #30cf79;

  /** secondary **/
  --ion-color-secondary: #838dae;
  --ion-color-secondary-rgb: 131, 141, 174;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #737c99;
  --ion-color-secondary-tint: #8f98b6;

  /** tertiary **/
  --ion-color-tertiary: #d1e7ed;
  --ion-color-tertiary-rgb: 209, 231, 237;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #b8cbd1;
  --ion-color-tertiary-tint: #d6e9ef;

  /** success **/
  --ion-color-success: #19ca6a;
  --ion-color-success-rgb: 25, 202, 106;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #16b25d;
  --ion-color-success-tint: #30cf79;

  /** warning **/
  --ion-color-warning: #ffaf37;
  --ion-color-warning-rgb: 255, 175, 55;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09a30;
  --ion-color-warning-tint: #ffb74b;

  /** danger **/
  --ion-color-danger: #f44;
  --ion-color-danger-rgb: 255, 68, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e03c3c;
  --ion-color-danger-tint: #ff5757;

  /** dark **/
  --ion-color-dark: #1f2933;
  --ion-color-dark-rgb: 31, 41, 51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1b242d;
  --ion-color-dark-tint: #353e47;

  /** medium **/
  --ion-color-medium: #b0b8bf;
  --ion-color-medium-rgb: 176, 184, 191;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #9ba2a8;
  --ion-color-medium-tint: #b8bfc5;

  /** light **/
  --ion-color-light: #fcfcfc;
  --ion-color-light-rgb: 252, 252, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dedede;
  --ion-color-light-tint: #fcfcfc;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    /** primary **/
    --ion-color-primary: #19ca6a;
    --ion-color-primary-rgb: 25, 202, 106;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #16b25d;
    --ion-color-primary-tint: #30cf79;

    /** secondary **/
    --ion-color-secondary: #838dae;
    --ion-color-secondary-rgb: 131, 141, 174;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #737c99;
    --ion-color-secondary-tint: #8f98b6;

    /** tertiary **/
    --ion-color-tertiary: #d1e7ed;
    --ion-color-tertiary-rgb: 209, 231, 237;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #b8cbd1;
    --ion-color-tertiary-tint: #d6e9ef;

    /** success **/
    --ion-color-success: #19ca6a;
    --ion-color-success-rgb: 25, 202, 106;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #16b25d;
    --ion-color-success-tint: #30cf79;

    /** warning **/
    --ion-color-warning: #ffaf37;
    --ion-color-warning-rgb: 255, 175, 55;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e09a30;
    --ion-color-warning-tint: #ffb74b;

    /** danger **/
    --ion-color-danger: #f44;
    --ion-color-danger-rgb: 255, 68, 68;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e03c3c;
    --ion-color-danger-tint: #ff5757;

    /** dark **/
    --ion-color-dark: #fcfcfc;
    --ion-color-dark-rgb: 252, 252, 252;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #dedede;
    --ion-color-dark-tint: #fcfcfc;

    /** medium **/
    --ion-color-medium: #b0b8bf;
    --ion-color-medium-rgb: 176, 184, 191;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #9ba2a8;
    --ion-color-medium-tint: #b8bfc5;

    /** light **/
    --ion-color-light: #1f2933;
    --ion-color-light-rgb: 31, 41, 51;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1b242d;
    --ion-color-light-tint: #353e47;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }*/

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }*/
}
