.header-md::after {
  background-image: none;
  height: 0;
  width: 0;
}

ion-header.header-md {
  box-shadow: none;
}

ion-button.button-solid {
  --background-activated: transparent;
  --background-hover: var(--ion-color-primary-contrast, #fff);
  --background-focused: var(--ion-color-primary-contrast, #fff);
  --background-activated-opacity: 0;
  --background-focused-opacity: 0.24;
  --background-hover-opacity: 0.08;
  --box-shadow: 0 6px 10px 0 rgba(25, 202, 106, 0.26);
  --border-radius: 100px;
  height: 48px;
  text-transform: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  --color: var(--white);
  &.button-disabled {
    --box-shadow: 0 6px 10px 0 rgba(176, 184, 191, 0.26);
    --background: var(--gray-3);
    --background-activated: var(--gray-3);
    --background-hover: var(--gray-3);
    --background-focused: var(--gray-3);
    opacity: 1;
    --color: var(--light-gray-2);
  }
}

ion-content {
  --padding-end: 24px;
  --padding-start: 24px;
  --padding-top: 24px;
  --padding-bottom: 24px;
}

#lang-change-popover {
  ion-item {
    .lang-icon {
      margin-right: 16px;
    }
    font-size: 14px;
    .current-lang {
      font-weight: bold;
    }
  }
}

// In caso di grandi schermi, fisso l'app al centro
// Disabilitato per problema con gli ion-popover
@media screen and (min-width: 600px) {
  body {
    background: var(--ion-background-color);
    ion-popover {
      --offset-x: -calc(100vh - 250px);
    }
  }

  body ion-app {
    max-width: 600px;
    left: calc(50% - 300px) !important;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal {
    --width: 100%;
    --height: 100%;
  }
}

// TOAST
.toast-success,
.toast-danger,
.toast-warning,
.toast-secondary {
  --ion-color-base: var(--light-gray) !important;
  --border-radius: 12px;
  font-weight: 600;
}

.toast-success {
  color: var(--ion-color-success);
  --box-shadow: 0 6px 10px 0 rgba(25, 202, 106, 0.26);
}

.toast-danger {
  color: var(--ion-color-danger);
  --box-shadow: 0 6px 10px 0 rgba(255, 68, 68, 0.26);
}

.toast-warning {
  color: var(--ion-color-warning);
  --box-shadow: 0 6px 10px 0 rgba(255, 175, 55, 0.26);
}

.toast-secondary {
  color: var(--ion-color-secondary);
  --box-shadow: 0 6px 10px 0 rgba(131, 141, 174, 0.26);
}

.pointer {
  cursor: pointer;
}

.empty-content {
  position: absolute;
  padding: 0 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

// FIX SELECT ALERT
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

ion-alert,
ion-action-sheet {
  font-family: var(--ion-default-font) !important;
}
